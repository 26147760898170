// NavigationBar.js
import React, { useState } from "react";
import "../css/navbar.css";

const NavigationBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const role = localStorage.getItem("role");

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    window.location.href = "/";
  };

  return (
    <div className="navbar-container">
      <div className="navbar-brand">Spot Auto</div>
      <button className="hamburger-menu" onClick={toggleSidebar}>
        ☰
      </button>
      <ul className={`nav-links ${isSidebarOpen ? "show" : ""}`}>
        {role === "admin" ? (
          <>
            <li>
              <a href="/users" className="nav-link">Users</a>
            </li>
            <li>
              <a href="/holiday-calendar" className="nav-link">Calendar</a>
            </li>
            <li>
              <a href="/attendance" className="nav-link">Attendance</a>
            </li>
            <li>
  <a href="/updates" className="nav-link">Updates</a>
</li>

            <li>
              <a href="/annual" className="nav-link">Annual Report</a>
            </li>
            <li>
              <a href="/sheet" className="nav-link">Sheet</a>
            </li>
            <li>
              <a href="/list" className="nav-link">List</a>
            </li>
            <li>
              <a href="/admin-dashboard" className="nav-link">Home</a>
            </li>
            {/* New List link for admin */}
           
          </>
        ) : (
          <>
            <li>
              <a href="/holiday-calendar" className="nav-link">Calendar</a>
            </li>
            <li>
              <a href="/attendance" className="nav-link">Attendance</a>
            </li>
            <li>
              <a href="/list" className="nav-link">List</a>
            </li>
            <li>
              <a href="/artist-dashboard" className="nav-link">Home</a>
            </li>
            {/* New List link for artist */}
           
          </>
        )}
        <li>
          <a href="/" className="nav-link" onClick={handleLogout}>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
