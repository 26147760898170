import React, { useState, useEffect } from "react";
import "../css/ArtistDashboard.css";  // Make sure this path matches your folder structure
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Linkify from "react-linkify";
import ProjectDropzone from "./ProjectDropzone";
import NavigationBar from "./Navbar";
import { apiInstance, socket } from "../config/config"; // Adjust paths to your config

const ArtistDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempCompletions, setTempCompletions] = useState({});

  // Fetch the projects for the logged-in artist
  const fetchProjects = async () => {
    const username = localStorage.getItem("username");
    try {
      const response = await apiInstance.get(`/api/forArtist/${username}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setProjects(response.data);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      toast.error("Failed to fetch projects");
    }
  };

  useEffect(() => {
    fetchProjects();

    // Listen for any updates to projects (socket.io)
    socket.on("projectUpdated", () => {
      fetchProjects();
    });

    // Cleanup the socket event on unmount
    return () => {
      socket.off("projectUpdated");
      socket.disconnect();
    };
  }, []);

  // Filter the projects by search term
  const filteredProjects = projects.filter(
    (project) =>
      project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Custom decorator for Linkify that ensures URLs have a protocol.
  const customComponentDecorator = (href, text, key) => {
    // If href doesn't start with http:// or https://, prepend http://
    const normalizedHref = href.match(/^(https?:\/\/)/i) ? href : `http://${href}`;
    return (
      <a href={normalizedHref} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  // Completion updates
  const handleCompletionChange = (projectId, value) => {
    setTempCompletions((prev) => ({ ...prev, [projectId]: value }));
  };

  const decodeEmail = (encodedEmail) => {
    return atob(encodedEmail); // Decodes the Base64 string into plain text
  };


  const updateCompletion = async (projectId) => {
    const newPercentage = tempCompletions[projectId];
    if (newPercentage != null) {
      try {
        await apiInstance.post(`/api/projects/${projectId}/completion`, {
          completionPercentage: newPercentage,
        });
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project._id === projectId
              ? { ...project, completionPercentage: newPercentage }
              : project
          )
        );
        toast.success("Completion updated successfully");
        setTempCompletions((prev) => {
          const newState = { ...prev };
          delete newState[projectId];
          return newState;
        });
      } catch (error) {
        console.error("Failed to update completion percentage:", error);
        toast.error("Failed to update completion percentage");
      }
    }
  };

  // Callback for dropzone upload success (optional)
  const handleUploadSuccess = (projectId, lastUploadedDate) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project._id === projectId
          ? { ...project, lastUploadedDate }
          : project
      )
    );
  };

  return (
    <div className="artist-dashboard-container">
      <NavigationBar />
      <ToastContainer />

      <div className="artist-search-and-table-container">
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="artist-search-input"
        />

        {/* Table Container */}
        <div className="artist-table-container">
          <table className="artist-projects-table">
            {/* ---- colgroup to define each column's width ---- */}
            <colgroup>
              <col /> {/* 1: Project Name */}
              <col /> {/* 2: Image Upload */}
              <col /> {/* 3: Description */}
              <col /> {/* 4: Start Date */}
              <col /> {/* 5: End Date */}
              <col /> {/* 6: Completion % */}
              <col /> {/* 7: Last Update Sent */}
            </colgroup>

            <thead>
              <tr>
                <th>Project Name</th>
                <th>Image Upload</th>
                <th>Description</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Completion %</th>
                <th>Last Update Sent</th>
              </tr>
            </thead>

            <tbody>
              {filteredProjects.map((project) => (
                <tr key={project._id}>
                  {/* Project Name */}
                  <td data-label="Project Name:">{project.projectName}</td>

                  {/* Image Upload - Dropzone */}
                  <td data-label="Image Upload:">
                    <ProjectDropzone
                      projectId={project._id}
                      onUploadSuccess={handleUploadSuccess}
                      // *** IMPORTANT: Pass the decoded (plain text) email ***
                      projectEmail={decodeEmail(project.clientEmail)}
                      projectName={project.projectName}
                    />

                  </td>

                  {/* Description & Comments */}
                  <td data-label="Description:">
                    <Linkify componentDecorator={customComponentDecorator}>
                      {project.description}
                    </Linkify>

                    {/* Comments if any */}
                    {project.comments && project.comments.length > 0 && (
                      <ul className="artist-comments-list">
                        {project.comments.map((comment, index) => (
                          <li key={index} className="artist-comment-item">
                            <strong>Comment {index + 1}:</strong>
                            <br />
                            <Linkify componentDecorator={customComponentDecorator}>
                              {comment.text}
                            </Linkify>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>

                  {/* Start Date */}
                  <td data-label="Start Date:">
                    {new Date(project.startDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>

                  {/* End Date */}
                  <td data-label="End Date:">
                    {new Date(project.endDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>

                  {/* Completion Percentage */}
                  <td data-label="Completion %:">
                    <input
                      type="number"
                      value={
                        tempCompletions[project._id] !== undefined
                          ? tempCompletions[project._id]
                          : project.completionPercentage
                      }
                      onChange={(e) =>
                        handleCompletionChange(project._id, e.target.value)
                      }
                      min="0"
                      max="100"
                      className="artist-completion-input"
                    />
                    <button
                      onClick={() => updateCompletion(project._id)}
                      className="artist-update-button"
                    >
                      Update
                    </button>
                  </td>

                  {/* Last Update Sent */}
                  <td data-label="Last Update Sent:">
                    {project.lastUploadedDate || "No info"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ArtistDashboard;
