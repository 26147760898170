import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../css/AdminDashboard.css"; // or your new dark theme CSS file
import FileUploadContainer from "./FileUploadContainer";
import Linkify from "react-linkify";
import NavigationBar from "./Navbar";
import { apiInstance } from "../config/config";

const AdminDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [artists, setArtists] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempCompletions, setTempCompletions] = useState({});
  const [formData, setFormData] = useState({
    projectName: "",
    clientEmail: "",
    description: "",
    assignedTo: "",
    startDate: "",
    endDate: "",
  });
  const [commentText, setCommentText] = useState({});

  useEffect(() => {
    fetchProjects();
    fetchArtists();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await apiInstance.get("/api/projects", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setProjects(response.data);
    } catch (error) {
      console.error("Failed to fetch projects", error);
    }
  };

  const fetchArtists = async () => {
    try {
      const response = await apiInstance.get("/api/auth/artists", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setArtists(response.data.map((artist) => artist.username));
    } catch (error) {
      console.error("Failed to fetch artists", error);
    }
  };

  // Utility: Add 15 business days
  const addWorkingDays = (startDate, numberOfDays) => {
    let date = new Date(startDate);
    while (numberOfDays > 0) {
      date = new Date(date.setDate(date.getDate() + 1));
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        numberOfDays--;
      }
    }
    return date;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "startDate") {
      const endDate = addWorkingDays(value, 15);
      updatedFormData = {
        ...updatedFormData,
        endDate: endDate.toISOString().slice(0, 10),
      };
    }
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.projectName || !formData.clientEmail || !formData.assignedTo) {
      toast.warn("Please fill out all required fields.");
      return;
    }

    try {
      // Prepare invoice data
      const invoiceData = {
        stripeInvoiceId: null,
        customerName: formData.projectName,
        customerEmail: formData.clientEmail,
        amountearned: 0,
        amountPaid: 0,
        paymentType: "Half",
        projectStatus: "Not Assigned",
        description: "(Half Payment)",
        assign: formData.assignedTo,
        startDate: formData.startDate,
        projectName: formData.projectName,
        lineItems: [
          {
            description: "(Half Payment)",
            amount: 0,
            quantity: 1,
          },
        ],
        endDate: formData.endDate,
        fileRequested: "Null",
      };

      // Prepare project data
      const projectData = {
        projectName: formData.projectName,
        clientEmail: formData.clientEmail,
        description: formData.description,
        assignedTo: formData.assignedTo,
        startDate: formData.startDate,
        endDate: formData.endDate,
        comments: [],
        completionPercentage: 0,
        lastUploadedDate: "",
      };

      // Post to invoices
      const invoiceResponse = await apiInstance.post("/api/invoices", invoiceData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      // Post to projects
      const projectResponse = await apiInstance.post("/api/projects", projectData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (invoiceResponse.status === 200 && projectResponse.status === 201) {
        toast.success("Project added to Annual Report and Projects collection.");
        setProjects((prevProjects) => [...prevProjects, projectResponse.data]);
        // Reset form
        setFormData({
          projectName: "",
          clientEmail: "",
          description: "",
          assignedTo: "",
          startDate: "",
          endDate: "",
        });
      }
    } catch (error) {
      console.error("Failed to create project or add to Annual Report.", error);
      toast.error("Failed to add project to Annual Report or Projects.");
    }
  };

  const filteredProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          project.description.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [projects, searchTerm]
  );

  const addComment = async (projectId, comment) => {
    try {
      await apiInstance.post(
        `/api/projects/addComment`,
        { projectId, comment },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      // Clear the comment textarea after submission
      setCommentText("");
      fetchProjects();
    } catch (error) {
      console.error("Failed to add comment", error);
    }
  };

  const updateAssignedArtist = async (projectId, artistId) => {
    try {
      await apiInstance.post(
        `/api/projects/updateArtist`,
        { projectId, artistId },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      fetchProjects();
    } catch (error) {
      console.error("Failed to update assigned artist", error);
    }
  };

  // Custom decorator for Linkify that ensures URLs have a protocol.
  const customComponentDecorator = (href, text, key) => {
    // If href doesn't start with http:// or https://, prepend http://
    const normalizedHref = href.match(/^(https?:\/\/)/i) ? href : `http://${href}`;
    return (
      <a href={normalizedHref} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  const handleCompletionChange = (projectId, value) => {
    setTempCompletions((prev) => ({ ...prev, [projectId]: value }));
  };

  const updateCompletion = async (projectId) => {
    const newPercentage = tempCompletions[projectId];
    if (newPercentage != null) {
      try {
        await apiInstance.post(`/api/projects/${projectId}/completion`, {
          completionPercentage: newPercentage,
        });
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project._id === projectId
              ? { ...project, completionPercentage: newPercentage }
              : project
          )
        );
        toast.success("Completion updated successfully");
        setTempCompletions((prev) => {
          const newState = { ...prev };
          delete newState[projectId];
          return newState;
        });
      } catch (error) {
        console.error("Failed to update completion percentage", error);
        toast.error("Failed to update completion percentage");
      }
    }
  };

  return (
    <div className="admin-dashboard-container">
      <NavigationBar />
      <ToastContainer />
      <div className="content-wrapper">
        {/* Left form */}
        <form onSubmit={handleSubmit} className="admin-form">
          <input
            name="projectName"
            value={formData.projectName}
            onChange={handleChange}
            placeholder="Project Name"
            type="text"
            className="input"
          />
          <input
            name="clientEmail"
            value={formData.clientEmail}
            onChange={handleChange}
            placeholder="Client Email"
            type="email"
            className="input"
          />
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            className="admin-textarea"
          />
          <select
            name="assignedTo"
            value={formData.assignedTo}
            onChange={handleChange}
            required
            className="select"
          >
            <option value="">Please select an artist</option>
            {artists.map((artist, index) => (
              <option key={index} value={artist}>
                {artist}
              </option>
            ))}
          </select>
          <input
            name="startDate"
            type="date"
            value={formData.startDate}
            onChange={handleChange}
            className="input"
          />
          <input
            name="endDate"
            type="date"
            value={formData.endDate}
            onChange={handleChange}
            className="input"
          />
          <button type="submit" className="admin-submit-button">
            Create Project
          </button>
        </form>

        {/* Right file upload container */}
        <FileUploadContainer />
      </div>

      <div className="admin-projects-table-wrap">
        <input
          type="text"
          placeholder="Search projects..."
          className="input search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <table className="admin-projects-table">
          <colgroup>
            <col /> {/* 1: Project Name */}
            <col /> {/* 2: Client Email */}
            <col /> {/* 3: Description */}
            <col /> {/* 4: Assigned To */}
            <col /> {/* 5: Start Date */}
            <col /> {/* 6: End Date */}
            <col /> {/* 7: Completion % */}
          </colgroup>

          <thead>
            <tr>
              <th>Project Name</th>
              <th>Client Email</th>
              <th>Description</th>
              <th>Assigned To</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Completion %</th>
            </tr>
          </thead>
          <tbody>
            {filteredProjects.map((project) => (
              <tr key={project._id}>
                <td data-label="Project Name:" style={{ textAlign: "left" }}>
                  {project.projectName}
                </td>
                <td data-label="Client Email:" style={{ textAlign: "left" }}>
                  {project.clientEmail}
                </td>
                <td data-label="Description:" style={{ textAlign: "left" }}>
                  {/* Wrap description text with Linkify */}
                  <Linkify componentDecorator={customComponentDecorator}>
                    {project.description}
                  </Linkify>
                  {project.comments && project.comments.length > 0 && (
                    <ul style={{ paddingLeft: 0, listStyle: "none" }}>
                      {project.comments.map((comment, index) => (
                        <li key={index} style={{ marginTop: "10px" }}>
                          <strong>Comment {index + 1}:</strong>
                          <br />
                          <Linkify componentDecorator={customComponentDecorator}>
                            <div style={{ whiteSpace: "pre-wrap" }}>{comment.text}</div>
                          </Linkify>
                        </li>
                      ))}

                    </ul>
                  )}
                  <div style={{ marginTop: "10px" }}>
                    <textarea
                      className="comment-textarea"
                      placeholder="Add a comment..."
                      value={commentText[project._id] || ""}
                      onChange={(e) =>
                        setCommentText((prev) => ({ ...prev, [project._id]: e.target.value }))
                      }
                      style={{ width: "100%", height: "50px" }}
                    />
                    <button
                      className="comment-submit-button"
                      onClick={() => {
                        if ((commentText[project._id] || "").trim()) {
                          addComment(project._id, commentText[project._id]);
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>

                </td>
                <td data-label="Assigned To:" style={{ textAlign: "left" }}>
                  <select
                    className="dark-select"
                    onChange={(e) => updateAssignedArtist(project._id, e.target.value)}
                    value={project.assignedTo || "Null"}
                  >
                    <option value="Null">Please select</option>
                    {artists.map((artist, index) => (
                      <option key={index} value={artist}>
                        {artist}
                      </option>
                    ))}
                  </select>
                </td>
                <td data-label="Start Date:" style={{ textAlign: "left" }}>
                  {new Date(project.startDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </td>
                <td data-label="End Date:" style={{ textAlign: "left" }}>
                  {new Date(project.endDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </td>
                <td data-label="Completion %" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    value={
                      tempCompletions[project._id] !== undefined
                        ? tempCompletions[project._id]
                        : project.completionPercentage
                    }
                    onChange={(e) => handleCompletionChange(project._id, e.target.value)}
                    min="0"
                    max="100"
                    className="completion-input"
                  />
                  <button onClick={() => updateCompletion(project._id)} className="update-button">
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;
