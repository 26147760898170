// src/components/Updates.js
import React, { useEffect, useState, useMemo } from "react";
import { apiInstance, socket } from "../config/config"; // Import socket here as well
import NavigationBar from "./Navbar";
import Modal from "react-modal";
import Slider from "react-slick";
import "../css/Updates.css";

// Import slick-carousel CSS files
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Helper function to format a date as DD/MM/YYYY
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

Modal.setAppElement("#root");

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [artists, setArtists] = useState([]);
  const [filterArtist, setFilterArtist] = useState("All");
  const [filterDate, setFilterDate] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({ artist: "All", date: "" });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);

  const fetchUpdates = async () => {
    try {
      const response = await apiInstance.get("/api/updates", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUpdates(response.data);
    } catch (error) {
      console.error("Error fetching updates:", error);
    }
  };

  const fetchArtists = async () => {
    try {
      const response = await apiInstance.get("/api/users", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // Filter users with role "artist"
      const artistUsers = response.data.filter(user => user.role === "artist");
      setArtists(artistUsers);
    } catch (error) {
      console.error("Error fetching artists:", error);
    }
  };

  useEffect(() => {
    fetchUpdates();
    fetchArtists();
  }, []);

  // Listen for updateCreated events
  useEffect(() => {
    socket.on("updateCreated", () => {
      fetchUpdates();
    });
    return () => {
      socket.off("updateCreated");
    };
  }, []);

  const artistOptions = useMemo(() => {
    return ["All", ...artists.map(artist => artist.username)];
  }, [artists]);

  const filteredUpdates = useMemo(() => {
    return updates.filter((update) => {
      const matchesArtist =
        appliedFilters.artist === "All" ||
        update.artist.toLowerCase() === appliedFilters.artist.toLowerCase();
      const updateDate = new Date(update.date).toISOString().split("T")[0];
      const matchesDate = appliedFilters.date ? updateDate === appliedFilters.date : true;
      return matchesArtist && matchesDate;
    });
  }, [updates, appliedFilters]);

  const handleApplyFilters = () => {
    setAppliedFilters({ artist: filterArtist, date: filterDate });
  };

  const handleResetFilters = () => {
    setFilterArtist("All");
    setFilterDate("");
    setAppliedFilters({ artist: "All", date: "" });
  };

  const openModalWithImages = (images) => {
    setCurrentImages(images);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Slider settings with conditional infinite and fade options
  const sliderSettings = {
    dots: true,
    infinite: currentImages.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: currentImages.length > 1
  };

  return (
    <div className="updates-container">
      <NavigationBar />
      <div className="updates-content">
        <h2>Project Updates</h2>

        <div className="filters-container">
          <div className="filter-item">
            <label htmlFor="artistFilter">Artist:</label>
            <select
              id="artistFilter"
              value={filterArtist}
              onChange={(e) => setFilterArtist(e.target.value)}
            >
              {artistOptions.map((artist, index) => (
                <option key={index} value={artist}>
                  {artist}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="dateFilter">Date:</label>
            <input
              type="date"
              id="dateFilter"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>
          <div className="filter-buttons">
            <button onClick={handleApplyFilters}>Filter</button>
            <button onClick={handleResetFilters}>Reset</button>
          </div>
        </div>

        <table className="updates-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Artist</th>
              <th>Project Name</th>
              <th>Images</th>
            </tr>
          </thead>
          <tbody>
            {filteredUpdates.map((update) => (
              <tr key={update._id}>
                <td>{formatDate(update.date)}</td>
                <td>{update.artist}</td>
                <td>{update.projectName}</td>
                <td>
                  <div className="images-preview">
                    {update.images.map((url, index) => (
                      <img
                        key={index}
                        src={url}
                        alt={`update-${index}`}
                        className="update-image-thumb"
                        onClick={() => openModalWithImages(update.images)}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredUpdates.length === 0 && (
          <p className="no-updates">No updates found for the applied filters.</p>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Preview"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <button onClick={closeModal} className="modal-close-button">
          Close
        </button>
        <Slider {...sliderSettings}>
          {currentImages.map((url, index) => (
            <div key={index} className="slider-image-container">
              <img src={url} alt={`slider-${index}`} className="modal-image" />
            </div>
          ))}
        </Slider>
      </Modal>
    </div>
  );
};

export default Updates;