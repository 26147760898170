import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Login.css";
import { apiInstance} from "../config/config"; // Adjust the import path according to your project structure

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    // Redirect based on role if token exists
    if (token) {
      if (role === "admin") {
        window.location = "/admin-dashboard";
      } else if (role === "artist") {
        window.location = "/artist-dashboard";
      }
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data } = await apiInstance.post("/api/auth/login", {
        username,
        password,
      });
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.userId);  // <-- Ensure userId is stored
      localStorage.setItem("username", username); // Store the username
      localStorage.setItem("role", data.role); // Save role directly from login response

      if (data.role === "admin") {
        window.location = "/admin-dashboard";
      } else if (data.role === "artist") {
        window.location = "/artist-dashboard";
      }
    } catch (error) {
      alert("Login failed: " + (error.response?.data?.message || ""));
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h2 className="login-header">Login to your account</h2>
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <label htmlFor="username" className="input-label">
              Username
            </label>
            <input
              id="username"
              name="username"
              type="text"
              required
              className="input-field"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              className="input-field"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-button">
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
