// HolidayCalendar.js
import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Default styles for react-calendar
import NavigationBar from "./Navbar";
import { apiInstance } from "../config/config";
import "../css/HolidayCalendar.css";
import * as XLSX from "xlsx"; // Import the xlsx library

const HolidayCalendar = () => {
  const [holidays, setHolidays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newHolidayDate, setNewHolidayDate] = useState("");
  const [newHolidayDescription, setNewHolidayDescription] = useState("");
  const role = localStorage.getItem("role");

  // Create a ref for the calendar container
  const calendarRef = useRef(null);

  useEffect(() => {
    fetchHolidays();
  }, []);

  // Listen for clicks outside the calendar to clear the active date
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        // Clicked outside the calendar: clear selected date
        setSelectedDate(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const formatToIST = (date) => {
    // Use 'en-CA' locale to get YYYY-MM-DD format and explicitly set the timeZone to IST.
    return new Date(date).toLocaleDateString('en-CA', { timeZone: 'Asia/Kolkata' });
  };


  const fetchHolidays = async () => {
    try {
      const response = await apiInstance.get("/api/holidays");
      setHolidays(response.data);
    } catch (error) {
      console.error("Failed to fetch holidays", error);
    }
  };

  const handleAddHoliday = async (e) => {
    e.preventDefault();
    if (!newHolidayDate || !newHolidayDescription) {
      alert("Please fill out all fields");
      return;
    }
    try {
      const payload = {
        date: newHolidayDate,
        description: newHolidayDescription,
      };
      await apiInstance.post("/api/holidays", payload);
      alert("Holiday added successfully");
      setNewHolidayDate("");
      setNewHolidayDescription("");
      fetchHolidays();
    } catch (error) {
      console.error("Error adding holiday", error);
      alert("Failed to add holiday");
    }
  };

  // Handler for processing the Excel file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });
      // Assuming data is in the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // Convert sheet to JSON array; each row is an array of cell values.
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Assuming the first row is a header (e.g., ["date", "description"])
      const [header, ...rows] = data;
      if (!header || header.length < 2) {
        alert("Excel file must have at least two columns: date and description");
        return;
      }

      const holidayPayloads = rows.map((row) => {
        let dateValue = row[0];
        // If the date is a number (Excel serial), convert it to a local date string.
        if (typeof dateValue === "number") {
          // Convert Excel serial to a JavaScript Date
          const jsDate = new Date(Math.round((dateValue - 25569) * 86400 * 1000));
          // Adjust the date by adding the timezone offset (in minutes)
          const adjustedDate = new Date(jsDate.getTime() + jsDate.getTimezoneOffset() * 60000);
          const year = adjustedDate.getFullYear();
          const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
          const day = String(adjustedDate.getDate()).padStart(2, "0");
          dateValue = `${year}-${month}-${day}`; // e.g., "2025-02-13"
        }
        // Otherwise, you may need to format the date if it comes in another format.
        return {
          date: dateValue,
          description: row[1],
        };
      });

      // Send each holiday payload to the backend.
      Promise.all(
        holidayPayloads.map((payload) =>
          apiInstance.post("/api/holidays", payload).catch((err) => {
            // Optionally, handle errors (e.g., if a holiday for that date already exists)
            console.error("Error importing holiday:", payload, err);
            return err;
          })
        )
      )
        .then(() => {
          alert("Holidays imported successfully");
          fetchHolidays();
        })
        .catch((err) => {
          console.error("Error during bulk import:", err);
          alert("Failed to import some holidays");
        });
    };
    reader.readAsBinaryString(file);
  };

  const handleDeleteAllHolidays = async () => {
    if (!window.confirm("Are you sure you want to delete all holidays?")) {
      return;
    }
    try {
      await apiInstance.delete("/api/holidays");
      alert("All holidays deleted successfully");
      fetchHolidays(); // Refresh the list after deleting
    } catch (error) {
      console.error("Error deleting holidays", error);
      alert("Failed to delete holidays");
    }
  };

  // Inside your HolidayCalendar component, before the return statement:
  const groupedHolidays = holidays.reduce((acc, holiday) => {
    if (acc[holiday.date]) {
      acc[holiday.date].push(holiday.description);
    } else {
      acc[holiday.date] = [holiday.description];
    }
    return acc;
  }, {});



  // Create a set of holiday dates for quick lookup.
  const holidayDates = new Set(holidays.map((holiday) => holiday.date));

  // tileContent to mark holiday dates in the calendar.
  const tileContent = ({ date, view }) => {
    if (view === "month") {
      // Format the tile's date to IST in "YYYY-MM-DD" format.
      const dateString = formatToIST(date);
      if (holidayDates.has(dateString)) {
        return <div className="holiday-marker">🎉</div>;
      }
    }
    return null;
  };

  return (
    <div className="holiday-calendar-container">
      <NavigationBar />
      <h2>Holiday Calendar</h2>
      <div className="calendar-wrapper" ref={calendarRef}>
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          tileContent={tileContent}
        />
      </div>
      <div className="holiday-info">
        <h3>Holiday List</h3>
        <ul>
          {Object.entries(groupedHolidays).map(([date, descriptions]) => (
            <li key={date}>
              {date} - {descriptions.join('/')}
            </li>
          ))}
        </ul>
        <p>Total Holidays: {Object.keys(groupedHolidays).length}</p>
      </div>

      {role === "admin" && (
        <>
          <div className="add-holiday-form">
            <h3>Add New Holiday</h3>
            <div className="add-holiday-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>Add New Holiday</h3>
              {role === "admin" && (
                <button
                  className="delete-button"
                  onClick={handleDeleteAllHolidays}
                >
                  Delete All Holidays
                </button>
              )}

            </div>

            <form onSubmit={handleAddHoliday}>
              <label>
                Date:
                <input
                  type="date"
                  value={newHolidayDate}
                  onChange={(e) => setNewHolidayDate(e.target.value)}
                  required
                />
              </label>
              <label>
                Description:
                <input
                  type="text"
                  value={newHolidayDescription}
                  onChange={(e) => setNewHolidayDescription(e.target.value)}
                  required
                />
              </label>
              <button type="submit">Add Holiday</button>
            </form>
          </div>
          <div className="import-holiday-form">
            <h3>Import Holidays from Excel</h3>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
            <p>
              Ensure your Excel file has a header row with columns like "date" and
              "description". The date should be in the format <code>YYYY-MM-DD</code>.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default HolidayCalendar;
