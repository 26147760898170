import React, { useState, useEffect, useMemo } from "react";
import NavigationBar from "./Navbar";
import "../css/Attendance.css";
import { apiInstance } from "../config/config";

// Import Chart.js components for the attendance summary graph.
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// Helper to get the current date in IST ("YYYY-MM-DD")
const getIndianDate = () => {
  const now = new Date();
  // IST offset: +5:30 hours = 19800000 ms
  const istTime = new Date(now.getTime() + 19800000);
  const year = istTime.getUTCFullYear();
  const month = String(istTime.getUTCMonth() + 1).padStart(2, "0");
  const day = String(istTime.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Helper: Get all weekdays (Monday–Friday) in the given month (YYYY-MM)
// (This function returns the full list for the entire month.)
const getWorkingDaysInMonth = (monthStr) => {
  const [year, mon] = monthStr.split("-").map(Number);
  const workingDays = [];
  const daysInMonth = new Date(year, mon, 0).getDate();
  for (let d = 1; d <= daysInMonth; d++) {
    const dateStr = `${year}-${String(mon).padStart(2, "0")}-${String(d).padStart(2, "0")}`;
    const dateObj = new Date(dateStr);
    const day = dateObj.getDay(); // 0 = Sunday, 6 = Saturday
    if (day !== 0 && day !== 6) {
      workingDays.push(dateStr);
    }
  }
  return workingDays;
};

// Helper: For full attendance report, if the selected month is current, only include working days that have passed.
const getEffectiveWorkingDaysForReport = (monthStr) => {
  const allWorkingDays = getWorkingDaysInMonth(monthStr);
  const currentDate = getIndianDate();
  const currentMonth = currentDate.slice(0, 7);
  if (monthStr === currentMonth) {
    return allWorkingDays.filter((day) => day <= currentDate);
  } else if (monthStr < currentMonth) {
    return allWorkingDays;
  } else {
    return [];
  }
};

const Attendance = () => {
  const username = localStorage.getItem("username") || "";
  const role = localStorage.getItem("role") || "";

  // Attendance state
  const [selectedDate] = useState(getIndianDate());
  const [attendanceMarked, setAttendanceMarked] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState("");
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [filterType, setFilterType] = useState("daily");

  useEffect(() => {
    fetchAttendance();
  }, [username, role, filterType, selectedDate]);

  const fetchAttendance = async () => {
    try {
      if (!username) {
        alert("Error: Missing username. Please log in again.");
        return;
      }
      let response;
      if (role === "admin") {
        response = await apiInstance.get(`/api/attendance`);
      } else {
        response = await apiInstance.get(`/api/attendance/report?username=${username}`);
      }
      const data = response.data;
      setAttendanceRecords(data);
      if (role === "artist") {
        const todayRecord = data.find((record) => record.date === selectedDate);
        if (todayRecord) {
          setAttendanceMarked(true);
          setCurrentTimestamp(todayRecord.timestamp || "");
        } else {
          setAttendanceMarked(false);
        }
      }
    } catch (error) {
      console.error("Error fetching attendance records:", error);
      alert("Failed to fetch attendance records.");
    }
  };

  const markAttendance = async () => {
    if (attendanceMarked) {
      alert("You have already marked your attendance for today.");
      return;
    }
    try {
      const timestamp = new Date().toLocaleTimeString("en-IN", { timeZone: "Asia/Kolkata" });
      setCurrentTimestamp(timestamp);
      const payload = { date: selectedDate, username, present: true, timestamp };
      await apiInstance.post("/api/attendance", payload);
      setAttendanceMarked(true);
      fetchAttendance();
      if (role === "artist") {
        fetchFullReport();
      }
    } catch (error) {
      console.error("Error marking attendance:", error);
      alert("Failed to mark attendance.");
    }
  };

  const getFilteredRecords = () => {
    if (filterType === "daily") {
      return attendanceRecords.filter((record) => record.date === selectedDate);
    } else if (filterType === "monthly") {
      const currentMonth = selectedDate.slice(0, 7);
      return attendanceRecords.filter((record) => record.date.startsWith(currentMonth));
    } else if (filterType === "yearly") {
      const currentYear = selectedDate.slice(0, 4);
      return attendanceRecords.filter((record) => record.date.startsWith(currentYear));
    }
    return attendanceRecords;
  };

  // Leave and report states
  const [selectedLeaveMonth, setSelectedLeaveMonth] = useState(new Date().toISOString().slice(0, 7));
  const [adminLeaveSummary, setAdminLeaveSummary] = useState([]);
  const [adminLeaveApplications, setAdminLeaveApplications] = useState([]);
  const [artistLeaveSummary, setArtistLeaveSummary] = useState({});
  const [artistLeaveApplications, setArtistLeaveApplications] = useState([]);
  const [leaveType, setLeaveType] = useState("single");
  const [singleDate, setSingleDate] = useState("");
  const [dayType, setDayType] = useState("full");
  const [multipleStartDate, setMultipleStartDate] = useState("");
  const [multipleEndDate, setMultipleEndDate] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [leaveCategory, setLeaveCategory] = useState("Casual");
  const [fullReport, setFullReport] = useState(null);

  // New state for holidays (from /api/holidays)
  const [holidays, setHolidays] = useState([]);

  // Annual summary for the whole year (for balance leave)
  const [annualSummary, setAnnualSummary] = useState({ balanceLeave: 24 });

  useEffect(() => {
    if (role === "admin") {
      fetchAdminLeaveData();
    } else {
      fetchArtistLeaveData();
      fetchFullReport();
    }
  }, [selectedLeaveMonth, username, role]);

  // Fetch annual summary (for artist)
  const fetchAnnualSummary = async () => {
    try {
      const currentYear = new Date().toISOString().slice(0, 4);
      const res = await apiInstance.get(`/api/attendance/leave/artist/annualSummary?username=${username}&year=${currentYear}`);
      setAnnualSummary(res.data);
    } catch (error) {
      console.error("Error fetching annual leave summary:", error);
    }
  };

  useEffect(() => {
    if (role !== "admin") {
      fetchAnnualSummary();
    }
  }, [username, role]);

  const fetchAdminLeaveData = async () => {
    try {
      const summaryRes = await apiInstance.get(`/api/attendance/leave/admin/summary?month=${selectedLeaveMonth}`);
      setAdminLeaveSummary(summaryRes.data);
      const applicationsRes = await apiInstance.get(`/api/attendance/leave/applications?month=${selectedLeaveMonth}`);
      setAdminLeaveApplications(applicationsRes.data);
    } catch (error) {
      console.error("Error fetching admin leave data:", error);
      alert("Failed to fetch leave data for admin.");
    }
  };

  const fetchArtistLeaveData = async () => {
    try {
      const summaryRes = await apiInstance.get(`/api/attendance/leave/artist/summary?month=${selectedLeaveMonth}&username=${username}`);
      setArtistLeaveSummary(summaryRes.data);
      const applicationsRes = await apiInstance.get(`/api/attendance/leave/artist/applications?month=${selectedLeaveMonth}&username=${username}`);
      setArtistLeaveApplications(applicationsRes.data);
    } catch (error) {
      console.error("Error fetching artist leave data:", error);
      alert("Failed to fetch leave data for artist.");
    }
  };

  const fetchFullReport = async () => {
    try {
      const res = await apiInstance.get(`/api/attendance/fullreport?username=${username}&month=${selectedLeaveMonth}`);
      setFullReport(res.data);
    } catch (error) {
      console.error("Error fetching full attendance report:", error);
    }
  };

  // Fetch holidays for the selected month
  const fetchHolidaysForMonth = async () => {
    try {
      const response = await apiInstance.get("/api/holidays");
      const filtered = response.data.filter((holiday) =>
        holiday.date.startsWith(selectedLeaveMonth)
      );
      setHolidays(filtered);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  useEffect(() => {
    fetchHolidaysForMonth();
  }, [selectedLeaveMonth]);

  // For the leave summary, use the full month working days (calendar-based) minus holidays.
  const effectiveWorkingDays = useMemo(() => {
    const totalWeekdays = getWorkingDaysInMonth(selectedLeaveMonth);
    const numWeekdays = totalWeekdays.length;
    const numHolidayWeekdays = holidays.filter((holiday) => {
      const day = new Date(holiday.date).getDay();
      return day !== 0 && day !== 6;
    }).length;
    return numWeekdays - numHolidayWeekdays;
  }, [selectedLeaveMonth, holidays]);

  // For the full attendance report, only count working days that have passed.
  const effectiveWorkingDaysForReport = useMemo(() => {
    return getEffectiveWorkingDaysForReport(selectedLeaveMonth);
  }, [selectedLeaveMonth]);

  const handleApplyLeave = async (e) => {
    e.preventDefault();
    try {
      let payload;
      if (leaveType === "single") {
        if (!singleDate) {
          alert("Please select a date for leave.");
          return;
        }
        payload = { username, date: singleDate, dayType, reason: leaveReason, leaveCategory };
      } else if (leaveType === "multiple") {
        if (!multipleStartDate || !multipleEndDate) {
          alert("Please select both start and end dates for your leave.");
          return;
        }
        payload = { username, startDate: multipleStartDate, endDate: multipleEndDate, reason: leaveReason, leaveCategory };
      }
      await apiInstance.post("/api/attendance/leave/apply", payload);
      alert("Leave application submitted successfully.");
      fetchArtistLeaveData();
      fetchFullReport();
      setSingleDate("");
      setMultipleStartDate("");
      setMultipleEndDate("");
      setLeaveReason("");
      setLeaveType("single");
      setDayType("full");
      setLeaveCategory("Casual");
    } catch (error) {
      console.error("Error applying for leave:", error);
      alert("Failed to apply for leave.");
    }
  };

  const handleLeaveAction = async (applicationId, action) => {
    try {
      await apiInstance.post("/api/attendance/leave/approve", { applicationId, action });
      alert(`Leave ${action}d successfully.`);
      fetchAdminLeaveData();
    } catch (error) {
      console.error(`Error during leave ${action}:`, error);
      alert(`Failed to ${action} leave.`);
    }
  };

  const chartData = {
    labels: ["Present", "Absent", "Paid Leave", "Unpaid Leave"],
    datasets: [
      {
        label: "Days Count",
        data: fullReport
          ? [fullReport.present, fullReport.absent, fullReport.paidLeave, fullReport.unpaidLeave]
          : [0, 0, 0, 0],
        backgroundColor: [
          "rgba(72, 187, 120, 0.6)",
          "rgba(229, 62, 62, 0.6)",
          "rgba(66, 153, 225, 0.6)",
          "rgba(237, 137, 54, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="attendance-container">
      <NavigationBar />

      {/* Attendance Section */}
      <div className="section attendance-section">
        <h2>Attendance Report</h2>
        <div className="attendance-date">
          <label>Date:</label>
          <input type="date" value={selectedDate} disabled />
        </div>
        {role !== "admin" && !attendanceMarked && (
          <button onClick={markAttendance} className="attendance-mark-btn">
            Mark Attendance
          </button>
        )}
        {attendanceMarked && (
          <p className="marked-info">
            ✅ Attendance marked at: {currentTimestamp}
          </p>
        )}
        <div className="filter-container">
          <label>Filter by:</label>
          <select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
        <table className="attendance-table">
          <thead>
            <tr>
              {role === "admin" && <th>Username</th>}
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredRecords().map((record, index) => (
              <tr key={index}>
                {role === "admin" && <td>{record.username}</td>}
                <td>{record.date}</td>
                <td>{record.present ? "✅ Present" : "❌ Absent"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <hr className="divider" />

      {/* Leave Management Section */}
      <div className="section leave-section">
        <h2>Leave Management</h2>
        <div className="month-selector">
          <label htmlFor="leaveMonth">Select Month:</label>
          <input
            id="leaveMonth"
            type="month"
            value={selectedLeaveMonth}
            onChange={(e) => setSelectedLeaveMonth(e.target.value)}
          />
        </div>
        {role === "admin" ? (
          <div className="admin-leave-view">
            <h3>Leave Summary</h3>
            <table className="summary-table">
              <thead>
                <tr>
                  <th>Artist Name</th>
                  <th>No of Working Days</th>
                  <th>No of Paid Holidays</th>
                  <th>No of Unpaid Holidays</th>
                  <th>Balance Leave</th>
                </tr>
              </thead>
              <tbody>
                {adminLeaveSummary.map((item, index) => (
                  <tr key={index}>
                    <td>{item.username}</td>
                    <td>{item.workingDays}</td>
                    <td>{item.paidHolidays}</td>
                    <td>{item.unpaidHolidays}</td>
                    <td>{item.balanceLeave}</td>

                  </tr>
                ))}
              </tbody>
            </table>
            <h3>Leave Applications</h3>
            <table className="leave-applications-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Date</th>
                  <th>Day Type</th>
                  <th>Type of Leave</th>
                  <th>Reason</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {adminLeaveApplications.map((app, index) => (
                  <tr key={index}>
                    <td>{app.username}</td>
                    <td>{app.date ? app.date : `${app.startDate} to ${app.endDate}`}</td>
                    <td>
                      {app.date
                        ? app.dayType === "full"
                          ? "Full Day"
                          : "Half Day"
                        : "-"}
                    </td>
                    <td>{app.leaveCategory}</td>
                    <td>{app.reason}</td>
                    <td>
                      {app.status === "pending" ? (
                        <>
                          <button onClick={() => handleLeaveAction(app._id, "approve")}>
                            Approve
                          </button>
                          <button onClick={() => handleLeaveAction(app._id, "cancel")}>
                            Cancel
                          </button>
                        </>
                      ) : (
                        <span>{app.status}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="artist-leave-view">
            <h3>Your Leave Summary</h3>
            <table className="summary-table">
              <thead>
                <tr>
                  <th>No of Working Days</th>
                  <th>No of Paid Holidays</th>
                  <th>No of Unpaid Holidays</th>
                  <th>Balance Leave</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{artistLeaveSummary.workingDays || 0}</td>
                  <td>{artistLeaveSummary.paidHolidays || 0}</td>
                  <td>{artistLeaveSummary.unpaidHolidays || 0}</td>
                  <td>{annualSummary.balanceLeave || 24}</td>
                </tr>
              </tbody>
            </table>
            <h3>Apply for Leave</h3>
            <form onSubmit={handleApplyLeave} className="leave-application-form">
              <div className="leave-type-selection">
                <label>
                  <input
                    type="radio"
                    name="leaveType"
                    value="single"
                    checked={leaveType === "single"}
                    onChange={() => setLeaveType("single")}
                  />{" "}
                  Single Day
                </label>
                <label>
                  <input
                    type="radio"
                    name="leaveType"
                    value="multiple"
                    checked={leaveType === "multiple"}
                    onChange={() => setLeaveType("multiple")}
                  />{" "}
                  Multiple Days
                </label>
              </div>
              {leaveType === "single" ? (
                <div className="single-day-input">
                  <label>
                    Date:
                    <input
                      type="date"
                      value={singleDate}
                      onChange={(e) => setSingleDate(e.target.value)}
                    />
                  </label>
                  <label>
                    Day Type:
                    <select value={dayType} onChange={(e) => setDayType(e.target.value)}>
                      <option value="full">Full Day</option>
                      <option value="half">Half Day</option>
                    </select>
                  </label>
                </div>
              ) : (
                <div className="multiple-day-input">
                  <label>
                    From:
                    <input
                      type="date"
                      value={multipleStartDate}
                      onChange={(e) => setMultipleStartDate(e.target.value)}
                    />
                  </label>
                  <label>
                    To:
                    <input
                      type="date"
                      value={multipleEndDate}
                      onChange={(e) => setMultipleEndDate(e.target.value)}
                    />
                  </label>
                </div>
              )}
              <div className="reason-input">
                <label>
                  Reason for Leave:
                  <textarea
                    value={leaveReason}
                    onChange={(e) => setLeaveReason(e.target.value)}
                  />
                </label>
              </div>
              <div className="leave-category">
                <label>
                  Type of Leave:
                  <select value={leaveCategory} onChange={(e) => setLeaveCategory(e.target.value)}>
                    <option value="Casual">Casual Leave</option>
                    <option value="Sick">Sick Leave</option>
                  </select>
                </label>
              </div>
              <button type="submit" className="apply-leave-btn">
                Submit
              </button>
            </form>
            <h3>Your Leave Applications</h3>
            <table className="leave-applications-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Day Type</th>
                  <th>Type of Leave</th>
                  <th>Reason</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {artistLeaveApplications.map((app, index) => (
                  <tr key={index}>
                    <td>{app.date ? app.date : `${app.startDate} to ${app.endDate}`}</td>
                    <td>
                      {app.date
                        ? app.dayType === "full"
                          ? "Full Day"
                          : "Half Day"
                        : "-"}
                    </td>
                    <td>{app.leaveCategory}</td>
                    <td>{app.reason}</td>
                    <td>{app.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

    
    </div>
  );
};

export default Attendance;
